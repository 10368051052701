import { routeToUrl } from "@app-routes";
import { FeatureGridItem } from "@components/LandingPage/HomePage/FeatureGridItem";
import { FeatureItem } from "@components/LandingPage/HomePage/FeatureItem";
import { PRESENTATION_MEETING_URL } from "@config/landingPageData";
import { AppInstance } from "@shared/utils/app-instance";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { LandingPageContainer } from "@ui/Container";
import { Grid } from "@ui/Grid";
import { Link } from "@ui/Link";
import { Hide } from "@uxf/core/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { CSSProperties, FC, memo } from "react";
import { useTheme } from "styled-components";

const ServiceFeatureTabComponent: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box as="section" pb={spacing(8)}>
            <LandingPageContainer>
                <Grid
                    gridColumnCount={[1, null, 2]}
                    gridGap={[spacing(8), null, null, spacing(12), spacing(16)]}
                    style={{ "--benefits-accent": theme.color.palette.primary } as CSSProperties}
                >
                    <FeatureGridItem
                        title={t("component-landing-home-service-feature-tab:client-zone")}
                        text={
                            !AppInstance.isSlovak ? (
                                <Trans
                                    i18nKey="component-landing-home-service-feature-tab:support-client-zone"
                                    components={{
                                        link: (
                                            <Link
                                                href={routeToUrl("landing-zone/service-zone")}
                                                textColor="brown"
                                                textFontWeight="medium"
                                                underline
                                            />
                                        ),
                                    }}
                                />
                            ) : null
                        }
                        imageSrc={
                            AppInstance.isCzech
                                ? "/static/images/landingPage/screenshot-cadastre.png"
                                : "/static/images/landingPage/screenshot-documents-sk.png"
                        }
                    />
                    <FeatureGridItem
                        title={t("component-landing-home-service-feature-tab:mass-communication")}
                        text={<>{t("component-landing-home-service-feature-tab:endless-calls-title")}</>}
                        imageSrc={
                            AppInstance.isCzech
                                ? "/static/images/landingPage/screenshot-overview-house.png"
                                : "/static/images/landingPage/screenshot-wall-sk.png"
                        }
                    />
                </Grid>
                <Hide when={AppInstance.isSlovak}>
                    <FeatureItem
                        aspectRatio={500 / 334}
                        imageSrc="/static/images/landingPage/video-service-zone-placeholder.jpg"
                        imageTitle={t("component-landing-home-service-feature-tab:play-video-distribution")}
                        text={t("component-landing-home-service-feature-tab:do-not-go-to-the-post")}
                        title={t("component-landing-home-service-feature-tab:online-distribution")}
                        videoId="q0-JYUuKUWI"
                    />
                </Hide>
                <FeatureItem
                    aspectRatio={500 / 334}
                    imageSrc={AppInstance.isCzech ? "/static/images/hp-domus-cz.png" : "/static/images/hp-domus-sk.png"}
                    imageTitle="DOMUS"
                    text={
                        <Trans
                            i18nKey="component-landing-home-service-feature-tab:anasoft.text"
                            components={{
                                anasoftLink: (
                                    <Link
                                        underline
                                        target="_blank"
                                        href={`https://www.anasoft.com/domus/${AppInstance.isSlovak ? "cz" : "sk"}`}
                                    />
                                ),
                                productListLink: (
                                    <Link
                                        underline
                                        target="_blank"
                                        href="https://poradna.sousede.cz/produktovy-list-pro-spravce"
                                    />
                                ),
                            }}
                        />
                    }
                    title={t("component-landing-home-service-feature-tab:anasoft.title")}
                    moreButton={
                        <Button kind="outline" variant="brown" href={PRESENTATION_MEETING_URL}>
                            {t("component-landing-home-service-feature-tab:anasoft.button")}
                        </Button>
                    }
                    isImageOnRightSide
                />
            </LandingPageContainer>
        </Box>
    );
};

export const ServiceFeatureTab = memo(ServiceFeatureTabComponent);

ServiceFeatureTab.displayName = "ServiceFeatureTab";
